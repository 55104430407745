import React from "react";
import { Row } from "react-bootstrap";

import bihLogo from "../hero-assets/brothers-in-health-logo-lg.png";

import { NavbarView } from "../navbar-view/navbar-view";
import { FooterView } from "../footer-view/footer-view";

import './brothers-view.scss';

export default function BrothersView() {

  return(
    
    <div id="brothersViewContainer">
      <NavbarView />
        <Row id="brothers-hero">
            <div className="about-heroContainer" id="brothersContainer">
              <div className="brothers-overlay"></div>
              <div className="heroText">
                  <p className="pageTitle">No two men are the same.</p>
                  <p className="subTitle">Breaking down cultural divides to move forward.</p>
                  <button id="brothersButton" className="brothersButton">
                    <a 
                    className="brothersButton-link"
                    href="mailto:jibrahim@ibrahimgroup.org?subject=Join Brothers in Health" target="_blank" rel="noreferrer">Join the Community</a></button>
            </div>
            </div>
        </Row>
        <Row className="brothersDescription"> 
          <Row className="brandContainer-bih">
            <img src={bihLogo} alt="Brothers in Health Logo" className="brandLogo"/>
          </Row>
          <div className="brothersDescription-container">
          <p className="textLeft descriptionText brothersDescription-header"><span className="bigger bold">Brothers in Health is a platform for increasing awareness and promoting advocacy for Men’s mental and social health.
          </span></p>
          <br/>
          <p className="textLeft descriptionText">
          Brothers in Health is based on the art of storytelling, self-reflection, and community. The core approach of Brothers in Health is to facilitate safe and challenging spaces to identify inner narratives of the self, explore their implications, and collectively construct maps of meaning. Brothers In Health Groups will read and watch popular media together, and discuss and analyze the  cultural and social values within. 
          <br/>
          <br/>
          <span className="bold">The objective of a gathering is to create a safe place to process various narratives and values in a changing world. This group is appropriate for all persons 18 and over who experience mild to moderate depression and/or anxiety. This is a private group, geared to reduce isolation, increase self-worth, and process thoughts through storytelling.</span></p>
          <br/>
          <p className="descriptionText textLeft">
          Some benefits you can expect from this group include:
            <ul>
              <li>Reduced isolation</li>
              <li>Refreshed perspectives on masculinity and identity </li>
              <li>Gain support and space to process difficult concepts</li>
              <li>Access to expert consultation in mental health</li>
              <li>Increased social awareness</li>
            </ul>
          </p>
          </div>
        </Row>
        <Row className="brothersDescription" id="fromJustin-brothers">
          <h2 className="brothersDescription-header">From Justin:</h2>
          <p className="textLeft fromJustin">
          "I’m a social worker and understand that humans in our world tend to operate based on the systems that have shaped us. <span className="bold">This includes systems of Gender and Sex. </span>
          <br/>
          <br/>
          Having studied the impact of <span className="bold">Hegemonic Masculinity Values</span> of men in the United States, we've created a refreshed approach to masculinity that leads to helping and healing outcomes and perspectives. Themes of masculinity in media and communications systems are examined, and we reflect on how these affect our lives, challenging inner narratives."
          <br/>
          <br/>
          <span className="bold">We challenge traditional masculine values that are harmful or need refining. </span>
          <ul>
            <li>Encountering and embracing the feminine within the masculine.</li>
            <li>XY is nothing without XX. </li>
            <li>Re-examining what it means to be a Real Man vs. a Good Man.</li>
            </ul>
          </p>
        </Row>
        <div className="scrollTop-container">
          <a href="/brothers-in-health#" id="scrollLink" className="scrollTop">↑</a>
        </div>
        <FooterView />
      </div>
  )
    }