import React from "react";
import { Image, Row } from "react-bootstrap";

import drLogo from "../hero-assets/ibrahim-dr-logo-med.png";

import { NavbarView } from "../navbar-view/navbar-view";
import { FooterView } from "../footer-view/footer-view";

import './dr-view.scss';

export default function DrView() {

  return(
    
    <div id="drViewContainer">
      <NavbarView />
        <Row id="dr-hero">
            <div className="about-heroContainer" id="drContainer">
              <div className="dr-overlay"></div>
              <div className="heroText">
                  <p className="pageTitle">Healing through your diverse experiences.</p>
                  <p className="subTitle">Couples therapy for interracial and intercultural couples.</p>
                  <button id="scheduleDr" className="scheduleButton"><a href="/schedule">Schedule<span id="appointment"> Appointment</span></a></button>
                </div>
            </div>
        </Row>
        
        <Row className="drDescription"> 
          <Row className="brandContainer">
            <Image src={drLogo} alt="Diverse Relationships Logo"
            className="brandLogo" id="drLogo" />
            <h1 className="brandName">Diverse Relationships</h1>
          </Row>
          <div className="drDescription-container">
            <p fluid className="textLeft descriptionText drDescription-content">
              <span className="bold bigger">Diverse Relationships believes that safe and healthy emotional communication can be built by acknowledging diverse family relationships in a safe environment. </span>
            <br/>
            <br/>
            First-generation Asian Americans, Pacific Islanders, Latino, Indigenouns, and Black Americans experience strains from life events and mental health challenges in unique ways, and often struggle to find a credentialed counselor who is culturally competent to acknowledge these important cultural dynamics.
            <br/>
            <br/>
            By acknowledging diverse identity and culture up-front, clients may feel affirmed in their experiences while addressing major life challenges. Together, we can identify the tools needed for managing life strains and mental health challenges unique to your personal experience.</p>
          </div>
        </Row>
        <Row className="drDescription" id="fromJustin-dr">
          <h2 className="drDescription-header">From Justin:</h2>
          <p className="textLeft descriptionText fromJustin">
          "I embrace the diversity of each individual and couple. <span className="bold">As a counselor who identifies as a multiethnic and multiracial Black man of color, I am passionate about honoring your unique identity and story. </span>
          <br/>
          <br/>
          Life and work stressors, multicultural issues, interpersonal oppression/racism, women's issues, shame/guilt, self-esteem, identity, emotional regulation, creativity, and interpersonal issues may be areas you want support in navigating. We will co-create our time together, based on what is salient and meaningful to you.
          <br/>
          <br/>
          <span className="bold">You are the expert of your own story, and I will offer avenues for exploration that have potential to add new areas of dimensionality, growth, insight, and expansion into your life.</span>
          <br/>
          <br/>
          {/* Sessions may also include and are not limited to: coping skills, explorations of meaning, embodiment/movement/ meditation exercises, and reflective writing. I am relational in approaching therapy with you and finding safety in our work together. I may also incorporate clinical education and skills such as <span className="bold">EMDR, hypnotherapy, and psycho-dynamic approaches. </span> */}
          I look forward to partnering with you and affirming your presence with hope, insight, and understanding."
          </p>
        </Row>
        <div className="scrollTop-container">
          <a href="/diverse-relationships#" id="scrollLink" className="scrollTop">↑</a>
        </div>
        <FooterView />
      </div>
  )
    }