import './App.css';

import MainView from './components/main-view/main-view';
import DrView from './components/diverse-relationships/dr-view';
import FaqView from './components/faq-view/faq-view';
import ContactView from './components/contact-view/contact-view';
import ServicesView from './components/services-view/services-view';
import BrothersView from './components/brothers-view/brothers-view';
import ScheduleView from './schedule-view/schedule-view';
// import BrothersViewConst from './components/brothers-view/brothers-view-construction';


import {BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
         <Route path="/" element={<MainView />} />
         <Route path="/diverse-relationships" element={<DrView />} />
        <Route path="/services" element={<ServicesView />} />
        <Route path="/brothers-in-health" element={<BrothersView />} />
        <Route path="/faqs" element={<FaqView />} />
        <Route path="/contact" element={<ContactView />} />
        <Route path="/schedule" element={<ScheduleView />} />
        </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
