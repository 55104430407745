import React from "react";
import { Col } from "react-bootstrap";

import igLogo from '../hero-assets/ibrahim-group-logo-small.png';

import { Hamburger } from "../hamburger/hamburger";

import './navbar-view.scss';

export class NavbarView extends React.Component{

  render(){
    return (
    <Col id="navbarContainer">        
        <div id="navbar">
          <div className="logoContainer">
            <img src={igLogo} alt="Ibrahim Group Logo" id="heroLogo"/>
          <h1 id="heroName"><a href="/">IBRAHIM GROUP</a></h1>
          </div>
          <ul id="navList-wide">
          <li class="navLink">
              <a href="/services">Services</a>
            </li>
            <li class="navLink">
              <a href="/diverse-relationships">Diverse Relationships</a>
            </li>
            <li class="navLink">
              <a href="/brothers-in-health">Brothers in Health</a>
            </li>
            <li class="navLink">
              <a href="/faqs">FAQ</a>
            </li>
            <li class="navLink">
              <a href="/contact">Contact</a>
            </li>
          </ul>
          <button id="schedule" className="scheduleButton scheduleNav"><a href="/schedule">Schedule<span id="appointment"> Appointment</span></a></button>
        </div>
        <Hamburger />

       </Col>
      )
    }
  }