import React from "react";
import { Row } from "react-bootstrap";
import { Carousel} from "react-bootstrap";

import { NavbarView } from "../navbar-view/navbar-view";

import './main-view.scss';
import { FooterView } from "../footer-view/footer-view";

export default function MainView() {

  return(
    
    <div id="mainViewContainer">
      <NavbarView />
        <Row id="about-hero">
            <Carousel id="heroCarousel" 
            controls=""
            className="heroContainer" 
            interval="6000"
            fade>

              {/* CAROUSEL ITEM 1 */}
              <Carousel.Item className="carouselOne">
              <div className="overlay"></div>
              <div className="heroText">
                  <h1 className="pageTitle">Who you are can set you free.</h1>
                  <p className="subTitle">Talk therapy guided by the experiences that make you... you.</p>
                  <button id="scheduleMain-view" className="scheduleButton"><a href="/schedule">Schedule<span id="appointment"> Appointment</span></a></button>
            </div>
              </Carousel.Item>

              {/* CAROUSEL ITEM 2 */}
              <Carousel.Item className="carouselTwo">
              <div className="overlay"></div>
              <div className="heroText">
                  <h1 className="pageTitle">Our differences can make us stronger.</h1>
                  <p className="subTitle">Utilizing your diverse experiences to create harmony.</p>
                  <button id="scheduleMain-view" className="scheduleButton"><a href="/schedule">Schedule<span id="appointment"> Appointment</span></a></button>
                  </div>
              </Carousel.Item>

               {/* CAROUSEL ITEM 3 */}
               <Carousel.Item className="carouselThree">
              <div className="overlay"></div>
              <div className="heroText">
                  <h1 className="pageTitle">What does it really mean to be a man?</h1>
                  <p className="subTitle">Creating a digital community where men can grow and learn together.</p>
                  <button id="scheduleMain-view" className="scheduleButton"><a href="/schedule">Schedule<span id="appointment"> Appointment</span></a></button>
                  </div>
              </Carousel.Item>


            </Carousel>
        </Row>
        <Row className="aboutDescription"> 
          <div className="aboutDescription-container">
            <h2 className="aboutTitle">About Ibrahim Group</h2>
            <p className="textLeft descriptionText"><span className="bold">Ibrahim Group</span> is a collection of therapy and community services geared towards raising awareness and inclusion of  <span className="bold">BIPOC </span>communities in mental health and wellness spaces, informed by culturally competent and humble perspectives. Mental health and wellness services have historically been controlled, curated, and distributed through homogenous channels, resulting in less than efficacious outcomes for ethnic and cultural minorities. This has created a deficit for many people of color  seeking and accessing relevant mental health treatments.
            <br/>
            <br/>
            Additionally, Ibrahim Group PLLC operates under the premise that <span className="bold">mental health and wellness services should be accessible to all people,</span> with diverse, equitable, and inclusive perspectives vital to all therapeutic spaces and communities seeking wholeness. </p>
          </div>
        </Row>
        <Row className="aboutTwo"> 
        <h2 className="aboutTitle">About Justin Ibrahim</h2>
            <div alt="Justin Ibrahim Headshot" id="headshot"></div>
          <div className="aboutDescription-container fromJustin">
            <p className="textLeft descriptionText"><span className="bold">Justin Ibrahim has been in caring professions for over 10 years, </span>with experience in youth development, ministry, university life, elderly care environments, hospitals, and mental health/group practice clinics. Justin has a <span className="bold">Master’s Degree in Wocial Work from Western New Mexico University</span> and founded Ibrahim Group in 2021 in response to the Covid-19 pandemic. Justin has extensive experience working with people from diverse cultural backgrounds. 
            <br/>
            <br/>
            Justin worked as a mental health provider with the <span className="bold"> Muckleshoot Behavioral Health Program, </span>serving the indigenous population with clinical and culturally attuned care. He was a clinical provider at <span className="bold">Harborview Medical Center,</span> providing outreach and social work services for people living with severe comorbid challenges. His aim is to provide telehealth counseling services for underrepresented populations in mental health and wellness spaces.
            <br/>
            <br/>
            The principles upon which Ibrahim Group approaches  <span className="bold">mental health and wellness include diversity, equity, and inclusion for clients as well as clinicians.</span> We aspire to connect, promote, and support clinicians and clients of color through a shared exploration of diversity, equity, and inclusion within mental health conversations and services. We acknowledge and affirm that these diverse backgrounds can serve as a great strength in the promotion of culturally appropriate & effective mental health care.</p>
          </div>
        </Row>
        <div className="scrollTop-container">
          <a href="/#" id="scrollLink" className="scrollTop">↑</a>
        </div>
        <FooterView/>
      </div>
  )
    }