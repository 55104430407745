import React from "react";
import { Row, Button } from "react-bootstrap";

import './footer-view.scss';

export class FooterView extends React.Component{

  render(){
    return (
    <Row id="footerContainer">
      <div className="footerIntro">
        <h2 className="footerHeader">One step closer to peace of mind</h2>
        <p className="footerDescription">
        Ready to talk? We're ready too! Schedule an appointment today to begin healing.
        </p>
      </div>

      <div className="links">

        <button className="scheduleFooter-container footerLink">
        <i class="fa-solid fa-calendar-check footerIcon"></i>
        <a href="/schedule" className="footerLink-a">
          <p>Schedule</p>
          </a>
        </button>

        <button className="contactFooter-container footerLink">
        <i class="fa-solid fa-phone footerIcon"></i>
        <a href="/contact">
          <p className="linkText">Contact</p>
          </a>
        </button>

        <Button id="linkedin"href="https://www.linkedin.com/in/justinpibrahim/" rel="noreferrer" target="_blank" alt="Visit LinkedIn" />

      </div>

      <Button id="psychologyToday" href="https://www.psychologytoday.com/us/therapists/justin-ibrahim-renton-wa/868564" target="_blank" alt="Learn more by visiting our Psychology Today Page" rel="noreferrer" />

      <p id="address">
        Ibrahim Group, PLLC.
        <br/>
        720 N 10th St., Ste A #1011,  
        Renton, WA 98057
        <br/>
        (206) 593-4358</p>
      
      <p id="copyright">
      © Copyright 2022, Ibrahim Group, PLLC. All Rights Reserved | Website by <a target="_blank" rel="noreferrer" href="https://pluribusmedia.com">Pluribus Media</a> 
      &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;    
      <a href="/privacy-policy.html" target="_blank" rel="noreferrer" alt="Privacy Policy">Privacy Policy</a>
      </p>
      
      <div className="privacyPolicy">

      </div>

       </Row>
      )
    }
  }