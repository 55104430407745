import React from "react";
import { Row } from "react-bootstrap";

import Iframe from 'react-iframe';

import { NavbarView } from "../navbar-view/navbar-view";
import { FooterView } from "../footer-view/footer-view";

import './contact-view.scss';

export default function ContactView() {

  return(
    
    <div id="contactViewContainer">
      <NavbarView />
      <Row className="sectionHeader-container">
        <h1 className="sectionTitle">Contact</h1>
      </Row>
      <div>
      <div className="contactContent">
        <div class="contactInformation">
          <h2>Questions?</h2>
          <br/>
    
          <a href="mailto:jibrahim@ibrahimgroup.org?subject=Question for Ibrahim Group" target="_blank" rel="noreferrer" className="bold" id="emailUs"><i class="fa-solid fa-envelope"></i>Email Us</a>
          <br/>
          <p><i class="fa-solid fa-phone"></i>(206) 593-4358</p>
          <br/>
          <p><i class="fa-solid fa-location-dot"></i>
          720 N 10th St., Ste A #1011
            <br/>Renton, WA 98057</p>

        </div>
        <Iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.583184203706!2d-122.20763414845156!3d47.498032779075466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906818348437ab%3A0x67602c1e322666de!2s720%20N%2010th%20St%2C%20Renton%2C%20WA%2098057!5e0!3m2!1sen!2sus!4v1666651612114!5m2!1sen!2sus"
          id="contactMap" 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade" />
      </div>
      </div>
      <FooterView />

      </div>
  )
    }