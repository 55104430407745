import React from "react";
import { Button, Row } from "react-bootstrap";

import { stack as Menu } from 'react-burger-menu';

import igLogo from "../hero-assets/ibrahim-group-logo-small.png";

import './hamburger.scss';

export class Hamburger extends React.Component{

render(){
  return (
  <Button id="hamburger" className="burger">
      <Menu id="hamburgerMenu" right>
        <Row className="logoContainer-menu">
            <a href="/"><img src={igLogo} alt="Ibrahim Group Logo" id="heroLogo-menu" /></a>
      </Row>
          <a id="services" className="menu-item" href="/services">Services</a>
          <a id="dr" className="menu-item" href="/diverse-relationships">Diverse Relationships</a>
          <a id="bih" className="menu-item" href="/brothers-in-health">Brothers in Health</a>
          <a id="faqs" className="menu-item" href="/faqs">FAQ</a>
          <a id="contact" className="menu-item" href="/contact">Contact</a>
          <a id="scheduleMenu" className="menu-item" href="/schedule">Schedule Appointment</a>
      </Menu>
     </Button>
    )
  }
}