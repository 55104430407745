import React from "react";
import { Row } from "react-bootstrap";

import { NavbarView } from "../navbar-view/navbar-view";
import { FooterView } from "../footer-view/footer-view";

import './faq-view.scss';

export default function FaqView() {

  return(
    
    <div id="faqViewContainer">
      <NavbarView />
      <Row className="sectionHeader-container">
        <h1 className="sectionTitle">Frequently Asked Questions</h1>
      </Row>

      {/* S E C T I O N  1 */}
      <Row className="faqSection-container">

        {/* Question 1.1 */}
        <div className="questionContainer">
            <details open> 
            {/* First one is open to show usage */}
                <summary className="faqQuestion">What is therapy? Why would I need to go see a social worker / counselor?</summary>
                <p className="faqAnswer">Therapy is a scientifically informed method of treatment for people who may be feeling overwhelmed or dissatisfied with their current quality of life.  You may be struggling with feelings of anxiety or depression, the challenges of managing a stressful life event, loss, difficult emotions, irritability, or erratic mood, roadblocks to achieving goals/personal growth, or other matters that may have a debilitating effect on your life.  
                <br/>
                <br/>
                A counselor will work with you to develop a collaborative plan for how to best treat such issues, and help you find relief from your more challenging symptoms by holding space together and processing together.</p>
              </details>
            </div>

            {/* Question 1.2 */}
        <div className="questionContainer">
            <details>
                <summary className="faqQuestion">Who do you work with? Adults, teens, couples?</summary>
                <p className="faqAnswer">Yes! While the majority of my clinical training involves working with adults, I also have a great deal of experience working with teens, young adults (~18-24), and couples in therapy as they manage life transitions. This includes premarital and post-marital counseling. 
                <br/>
                <br/>
                I enjoy working with AAPI and BIPOC clients and their loved ones to identify solutions to improve quality of life while honoring important cultural/identity values and perspectives. I also work with people who do not identify as AAPI or BIPOC and believe that cultural perspectives are still applicable in treatment.</p>
              </details>
            </div>

            {/* Question 1.3 */}
        <div className="questionContainer">
            <details>
                <summary className="faqQuestion">If I start therapy, does that mean I have to take medication?</summary>
                <p className="faqAnswer">No. Many of the clients that I see in therapy are not taking medication, while others may find it helpful. <br/>
                <br/>
                If I feel like you could benefit from medication support in addition to seeing me in therapy, you and I can talk about considering an adjunctive consultation with a psychiatrist.
                <br/>
                <br/>
                I enjoy working with AAPI and BIPOC clients and their loved ones to identify solutions to improve quality of life while honoring important cultural/ identity values and perspectives. I also work with people who do not identify as AAPI or BIPOC and believe that cultural perspectives still are applicable in treatment.</p>
              </details>
            </div>

            {/* Question 1.4 */}
        <div className="questionContainer">
            <details>
                <summary className="faqQuestion">How does the therapy process start and how long does it last?</summary>
                <p className="faqAnswer">Therapy starts with something called the “intake process.”  This means that I will meet with you over the course of 1-2 sessions and ask you a series of questions about what brought you to therapy, your medical and mental health history, your current quality of social, familial, and occupational functioning, as well as what goals you would like to accomplish in our work together. 
                <br/>
                <br/>
                At the conclusion of the intake process, we will determine a meeting schedule that takes into consideration your availability, your presenting issues and concerns, as well as your stated objectives for treatment.  The length of how long you need to attend therapy varies with each patient.  Some patients notice their symptoms subsiding after only 4-5 sessions, while other patients may need to come meet with me for longer.  Others may see the value of having regular therapy and choose to continue care throughout their life.
                <br/>
                <br/>
                You may decide to meet with me weekly, every other week, or come back every once in awhile for check-ups, just like you would do with a medical doctor.  You and I will decide together when is the best time for you to adjust your therapy needs.
                </p>
              </details>
            </div>

             {/* Question 1.5*/}
        <div className="questionContainer">
            <details>
                <summary className="faqQuestion">What kinds of issues do you treat?</summary>
                <p className="faqAnswer">Living in the hustling, bustling Seattle community provides its own unique challenges for teens and adults alike.  Many of the issues that I treat in therapy for clients include cultural confusion, feelings of sadness/frustration that can come with managing relationships or friendships, depression, anxiety, familial discord, as well as issues surrounding cultural identity and acceptance.  
                <br/>
                <br/>
                Issues that I treat in therapy for teen clients include depression, anxiety, personal growth/stage of life questions, relationships/infidelity, familial discord, and occupational stress. 
                </p>
              </details>
            </div>

             {/* Question 1.6 */}
        <div className="questionContainer">
            <details>
                <summary className="faqQuestion">Have you ever been in therapy?</summary>
                <p className="faqAnswer">Many times patients will wonder if their therapist has been in therapy himself.  I’ve always found it important to “practice what I preach,” meaning that I, too, have been a patient in therapy.  
                <br/>
                <br/>
                This benefits me in my role as a clinician by helping me to maintain a clearer focus on the issues that my patients are bringing up in their own therapy, while also providing me with some empathy and perspective into what it feels like to be a client in therapy. 
                </p>
              </details>
            </div>

             {/* Question 1.7 */}
        <div className="questionContainer">
            <details>
                <summary className="faqQuestion">As a parent, how do I know when it is time to enroll my teen in therapy?</summary>
                <p className="faqAnswer">Every teen will experience some degree of challenges or conflicts, as we know that to be an inevitable part of growing up.  It is when those challenges and conflicts become more pervasive or significantly interfere with a child’s day-to-day functioning that it may be time to consider enrolling him/her in therapy. 
                <br/>
                <br/>
                If you feel as though your efforts as a parent are not enough in helping to resolve your child’s issues, or other forms of support (teachers, coaches, clergy, school counselors, etc.) are only treating part of the problem, then perhaps a referral to a counselor would be helpful.
                </p>
              </details>
            </div>

             {/* Question 1.8 */}
        <div className="questionContainer">
            <details>
                <summary className="faqQuestion">Will I be a part of my teen’s therapy process? </summary>
                <p className="faqAnswer">Absolutely.  While your role in therapy may shift given your child’s age and developmental stage, I understand taking a family-focused approach to treating a teen’s presenting issues and concerns. 
                <br/>
                <br/>
                This may include parent collateral and/or family therapy sessions in addition to your teen’s individual therapy sessions, as well as providing you with tips and feedback between sessions to help create a home environment that supports the child’s growth and healing.
                </p>
              </details>
            </div>

            {/* Do not put questions after this without starting a new section */}
      </Row>
      <div className="faqTrailer">
        <div className="faqTrailer-content">
          <h2 className="faqTrailer-title aboutTitle">More questions?</h2>
          <a href="mailto:jibrahim@ibrahimgroup.org?subject=Question for Ibrahim Group" target="_blank" className="emailUs-faq" rel="noreferrer" id="emailUs">Email Us</a>
          </div>
        </div>
      <FooterView />
      </div>
  )
    }