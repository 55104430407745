import React from "react";
import { Row } from "react-bootstrap";

import { NavbarView } from "../navbar-view/navbar-view";

import './services-view.scss';
import { FooterView } from "../footer-view/footer-view";

export default function ServicesView() {

  return(
    
    <div id="servicesViewContainer">
      <NavbarView />
      <Row className="sectionHeader-container">
        <h1 className="sectionTitle">Services</h1>

       
      </Row>
      <Row id="servicesContainer">
          <div className="servicesColumn servicesLeft">
            <h3>For Individuals</h3>
            <p>
            $130 per 55-minute session
            <br/>
            <br/>
            $180 per 90-minute hypnotherapy session
            </p>
            <button id="scheduleIndy" className="scheduleButton scheduleServices"><a href="/schedule">Schedule</a></button>
          </div>
          <div className="servicesColumn servicesRight">
          <h3>For Couples</h3>
            <p>
            $130 per 55-minute session
            </p>
            <button id="scheduleCouples" className="scheduleButton scheduleServices"><a href="/schedule">Schedule</a></button>
          </div>
          
      </Row>
      <div className="servicesList">
        <div className="servicesContent">
          <h3 className="servicesList-header">
            Ibrahim Group provides services for the following:
            </h3> 
            <ul className="services">
              <li className="servicesItem">
                Couples Counseling
              </li>
              <li className="servicesItem">
                Couples Therapy
              </li>
              <li className="servicesItem">
              Relationship Counseling
              </li>
              <li className="servicesItem">
              Interpersonal Skills
              </li>
              <li className="servicesItem">
              Marriage and Premarital Counseling
              </li>
              <li className="servicesItem">
              Interracial / Intercultural Couple’s Counseling
              </li>
              <li className="servicesItem">
              Partner Counseling
              </li>
              <li className="servicesItem">
              BIPOC Counseling
              </li>
              <li className="servicesItem">
              AAPI Counseling Therapy
              </li>
              <li className="servicesItem">
              Group Counseling
              </li>
              <li className="servicesItem">
              Men’s Mental Health Counseling (Individual or Group)
              </li>
              <li className="servicesItem">
              Safety Planning
              </li>
              <li className="servicesItem">
              Togetherness / Embodiment Practice
              </li>
              <li className="servicesItem">
              Hypnosis Therapy
              </li>
              <li className="servicesItem">
              Trauma Reprocessing
              </li>
              <li className="servicesItem">
              Cognitive Behavioral Therapy
              </li>
              <li className="servicesItem">
              Narrative Therapy
              </li>
            </ul>
            </div>
      </div>


      <div className="servicesDescription-container">
            <p className="servicesDescription bold">
              Currently, services are conducted remotely for the safety of the therapist and patients. In-person appointments may be available upon request and approval.
              </p>
              <p>
              
              Sliding-scale pricing may be available for clients on a case-by-case basis.
              <br/>
              <br/>
            </p>
            <p className="servicesDescription">
              The Ibrahim Group provides therapy and counseling for individuals and couples in the greater Seattle area, including (but not limited to) Renton, Tukwila, Cascade-Fairwood, East Renton Highlands, Newcastle, Bryn Mawr-Skyway, Rainier Valley, New Holly, Beacon Hill, SeaTac, East Hill-Meridian, Maple Heights-Lake Desire, Mercer Island, Kent, Normandy Park, Highline, Des Moines, Burien,  Bellevue, Redmond, Factoria, and White Center.
              </p>
          </div>
        <div className="scrollTop-container">
          <a href="/services#" id="scrollLink" className="scrollTop">↑</a>
        </div>
        <FooterView/>
      </div>
  )
    }