import React from "react";
import { Row } from "react-bootstrap";

import Iframe from 'react-iframe';
// import { FooterView } from "../components/footer-view/footer-view";

import { NavbarView } from "../components/navbar-view/navbar-view";

import './schedule-view.scss';

export default function ScheduleView() {

  return(
    
    <div id="contactViewContainer">
      <NavbarView />
      <Row className="sectionHeader-container">
        <h1 className="sectionTitle">Schedule an Appointment</h1>
      </Row>
      <div className="troubleContainer">
        Having trouble? Try scheduling&nbsp;<a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3QWaS-jDSTm7NcfJWxg1qFFUAEU1ObF6UEwlXD4dpeVv4YcQi_dbSWV1W_Jwz0aPecYcEFQ7_W?gv=true"> here </a> &nbsp;instead.
      </div>
      <div>
        <Iframe 
          url="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3QWaS-jDSTm7NcfJWxg1qFFUAEU1ObF6UEwlXD4dpeVv4YcQi_dbSWV1W_Jwz0aPecYcEFQ7_W?gv=true"
          // width="320px"
          // height="100vh"
          id="embedSchedule"
          className=""
          display="inline-block"
          position="relative"/>
      </div>
      </div>

  )
    }